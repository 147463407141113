import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';

@Component({
  selector: 'app-footer',
  providers: [ContentfulService],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email: string;
  social: string;

  constructor(
    private contentful: ContentfulService
  ) { }

  async ngOnInit() {
    this.email = await this.contentful.getContent('19VxZMev4OIcqS2Cwuwu6G', 'email');
    this.social = await this.contentful.getContent('19VxZMev4OIcqS2Cwuwu6G', 'social');
  }

}
