import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public trackEvent(category: string, action: string, label: string): void {
    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
}
