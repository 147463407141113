import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AnswerOption } from './contentful.service';
import * as _ from 'lodash';
import { AnalyticsService } from './analytics.service';

export interface SurveyAnswers {
  [questionId: string]: AnswerOption[];
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public answers: SurveyAnswers = {};
  public observableAnswers = new BehaviorSubject<SurveyAnswers>(this.answers);
  public questionIdMap = {
    '7AHHZrPTTaueoqcu2Ae6K4': 'demographicFactorTrags',
    '5g1Ipqq9D2cSEmWYuAA0sC': 'issueTags',
    '1sIRefFv6sGEiwmQi0UUSO': 'industryTags',
    '3DZ6O8imgo06OkeQEAuqk8': 'stageTags',
  };

  constructor(private analytics: AnalyticsService) { }

  public toggleAnswer(questionId: string, answer: AnswerOption, allowMultipleAnswers: boolean): void {
    this.doToggle(questionId, answer, allowMultipleAnswers);
    this.observableAnswers.next(this.answers);
    console.log(this.answers);
  }

  private doToggle(questionId: string, answer: AnswerOption, allowMultipleAnswers: boolean): void {
    // If question hasn't been answered yet
    if (!this.answers[questionId] || !this.answers[questionId].length) {
      this.answers[questionId] = [answer];
      this.analytics.trackEvent('Survey', this.questionIdMap[questionId], answer.name);
      return;
    }

    const answersLength = this.answers[questionId].length;
    const alreadySelected = !!_.find(this.answers[questionId], {name: answer.name});

    // If already selected, unselect it
    if (alreadySelected) {
      const index = _.indexOf(this.answers[questionId], answer);
      this.answers[questionId].splice(index, 1);

      // If last element, remove question from answers{}
      if (!this.answers[questionId].length) {
        delete this.answers[questionId];
      }
      return;
    }

    // If room for another selection
    if (allowMultipleAnswers) {
      this.answers[questionId].push(answer);
      this.analytics.trackEvent('Survey', this.questionIdMap[questionId], answer.name);
      return;
    }

    // If already item in array, replace it with new selection
    this.answers[questionId][0] = answer;
    this.analytics.trackEvent('Survey', this.questionIdMap[questionId], answer.name);
    console.log(this.answers);
  }

  public answerIsSelected(questionId: string, answerName: string): boolean {
    if (!this.answers[questionId]) {
      return false;
    }

    return !!_.find(this.answers[questionId], { name: answerName });
  }
}
