import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../core/survey.service';

@Component({
  selector: 'app-survey-drawer',
  templateUrl: './survey-drawer.component.html',
  styleUrls: ['./survey-drawer.component.scss']
})
export class SurveyDrawerComponent implements OnInit {

  constructor(private survey: SurveyService) { }

  ngOnInit() {
    this.survey.observableAnswers.subscribe(answers => {
      console.log('woo');
    });
  }

}
