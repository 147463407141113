import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';

@Component({
  selector: 'app-home',
  providers: [ContentfulService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {
  landing: string;
  headline: string;
  body: string;

  constructor(
    private contentful: ContentfulService
  ) { }

  async ngOnInit() {
    this.landing = await this.contentful.getContent('pzZesmaJhuo4yqymIKSeo', 'landing');
    this.headline = await this.contentful.getContent('pzZesmaJhuo4yqymIKSeo', 'headline');
    this.body = await this.contentful.getContent('pzZesmaJhuo4yqymIKSeo', 'body');
  }

}
