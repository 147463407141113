import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgMasonryGridModule } from 'ng-masonry-grid';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HomeComponent } from './components/home/home.component';
import { HubComponent } from './components/hub/hub.component';
import { SurveyComponent } from './components/survey/survey.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SurveyDrawerComponent } from './components/survey-drawer/survey-drawer.component';
import { CardTagsComponent } from './components/card-tags/card-tags.component';

import { MatButtonModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardContactComponent } from './components/card-contact/card-contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HubComponent,
    SurveyComponent,
    HeaderComponent,
    FooterComponent,
    SurveyDrawerComponent,
    CardTagsComponent,
    CardContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatDividerModule,
    MatTooltipModule,
    NgMasonryGridModule,
    ScrollToModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
