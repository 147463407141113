import { Component, OnInit, Input } from '@angular/core';
import { SurveyService } from '../../core/survey.service';

@Component({
  selector: 'app-card-tags',
  templateUrl: './card-tags.component.html',
  styleUrls: ['./card-tags.component.scss']
})
export class CardTagsComponent implements OnInit {
  @Input() title: string;
  @Input() tags: any[];
  @Input() questionId: string;

  constructor(private survey: SurveyService) { }

  ngOnInit() {}

  isMatchingTag(): boolean {
    let isMatch = false;

    this.tags.forEach(tag => {
      if (this.survey.answerIsSelected(this.questionId, tag.fields.name)) {
        isMatch = true;
      }
    });

    return isMatch;
  }
}
