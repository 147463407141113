import { Component, OnInit } from '@angular/core';
import { ContentfulService, AnswerOption, SurveyQuestion } from '../../core/contentful.service';
import { SurveyService } from '../../core/survey.service';

@Component({
  selector: 'app-survey',
  providers: [ContentfulService],
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  questions: SurveyQuestion[];

  constructor(
    private contentful: ContentfulService,
    private survey: SurveyService
  ) { }

  async ngOnInit() {
    this.questions = await this.contentful.getSurvey();
    console.log(this.questions);
  }
}
