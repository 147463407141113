import { Component, OnInit } from '@angular/core';
import { ContentfulService, AnswerOption } from '../../core/contentful.service';
import { SurveyAnswers, SurveyService } from '../../core/survey.service';
import { Masonry, MasonryGridItem } from 'ng-masonry-grid';
import * as contentful from 'contentful';
import * as _ from 'lodash';

const tags = [
  {
    questionId: '1sIRefFv6sGEiwmQi0UUSO',
    name: 'Industry Tags',
    id: 'industryTags',
  }, {
    questionId: '3DZ6O8imgo06OkeQEAuqk8',
    name: 'Stage Tags',
    id: 'stageTags',
  }, {
    questionId: '5g1Ipqq9D2cSEmWYuAA0sC',
    name: 'Issue Tags',
    id: 'issueTags',
  }
];
@Component({
  selector: 'app-hub',
  providers: [ContentfulService],
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})
export class HubComponent implements OnInit {
  private resources: contentful.Entry<any>[] = [];
  private filteredResources: contentful.Entry<any>[] = [];
  private masonryOptions = {
    animationEffect: 'effect-2',
    viewportFactor: 0,
    fitWidth: true,
  };
  private showGrid = false;
  private masonry: Masonry;
  tags = tags;

  constructor(
    private contentfulService: ContentfulService,
    private surveyService: SurveyService
  ) { }

  async ngOnInit() {
    this.resources = await this.contentfulService.getEntries('organization');
    console.log('RESOURCES', this.resources);
    this.filterResources();
    this.showGrid = true;
    console.log(this.surveyService.answers);
  }

  private layoutComplete(): void {
    window.scrollTo(0, 0);
  }

  private filterResources() {
    // Listen to survey answers being updated
    this.surveyService.observableAnswers.subscribe((answers: SurveyAnswers) => {
      this.showGrid = false;

      // If no selected survey answers, reset to all resources
      if (!answers || !_.keys(answers).length) {
        this.resetFilters();
        return;
      }

      const questionIds = _.keys(answers);

      this.filteredResources = _.filter(this.resources, resource => {
        const data = resource.fields;
        let matchesFilter = true;

        // Loop through each filter that has been set
        questionIds.forEach((questionId: string) => {
          const prop = this.surveyService.questionIdMap[questionId];

          // If resource doesn't have the tag type, filter out immediately
          if (!data[prop] || !data[prop].length) {
            matchesFilter = false;
          } else {
            const resourceTags = data[prop];
            const qAnswers: AnswerOption[] = answers[questionId];
            let questionMatchFound = false;

            // For multi select tags, as long as at least one matches it counts
            // If none match, filter out
            resourceTags.forEach((tag: contentful.Entry<any>) => {
              console.log(_.find(qAnswers, { name: tag.fields.name }));
              if (_.find(qAnswers, { name: tag.fields.name })) {
                questionMatchFound = true;
              }
            });

            if (!questionMatchFound) {
              matchesFilter = false;
            }
          }
        });

        // If haven't been filtered out, return true
        return matchesFilter;
      });

      this.displayGrid();
    });
  }

  private resetFilters() {
    this.filteredResources = this.resources;
    this.displayGrid();
  }

  // Display the grid. Need the setTimeout to fix weird display issues
  private displayGrid() {
    setTimeout(() => {
      this.showGrid = true;
    }, 0);
  }
}
