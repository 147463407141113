import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import * as _ from 'lodash';

const client = contentful.createClient({
  space: 'c56e741lm2le',
  accessToken: '122cc3ed8d3e3143d5e84212ec33dd50cc4ac85cfd75a7784cc3647797314f22'
});

@Injectable()
export class ContentfulService {

  // get one field from a specific resource
  public getContent(entityId: string, fieldName: string) {
    return new Promise<string>((resolve, reject) => {
      client.getEntry(entityId)
        .then(entry => {
          resolve(entry.fields[fieldName]);
        })
        .catch(error => reject(error));
    });
  }

  // Get all entries of a certain type (i.e., organization)
  public getEntries(contentType: string, options?: any) {
    let params = {
      content_type: contentType
    };

    if (options) {
      params = Object.assign(params, options);
    }

    console.log(params);

    return new Promise<contentful.Entry<any>[]>((resolve, reject) => {
      client.getEntries(params)
        .then(entries => {
          resolve(entries.items);
        })
        .catch(error => reject(error));
    });
  }

  // get all survey questions and their related answer options
  public async getSurvey() {
    const options = {
      order: 'fields.order'
    };
    const questions: contentful.Entry<any>[] = await this.getEntries('surveyQuestion', options);
    const survey: SurveyQuestion[] = [];
    questions.map(question => survey.push(new SurveyQuestion(question)));
    return survey;
  }
}

// TYPINGS //
export class AnswerOption {
  name: string;
  id: string;

  constructor(name: string, id: string) {
    this.name = name;
    this.id = id;
  }
}

export class SurveyQuestion {
  text: string;
  id: string;
  answerOptions: AnswerOption[] = [];
  allowMultipleAnswers: boolean;

  constructor(questionObj: contentful.Entry<any>) {
    this.text = questionObj.fields.questionText;
    this.id = questionObj.sys.id;
    this.allowMultipleAnswers = questionObj.fields.allowMultipleAnswers;

    _.map(questionObj.fields.answerOptions, option => {
      if (option && option.fields && option.sys) {
        this.answerOptions.push(new AnswerOption(option.fields.name, option.sys.id));
      }
    });
  }
}
