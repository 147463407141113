import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html',
  styleUrls: ['./card-contact.component.scss']
})
export class CardContactComponent implements OnInit {
  @Input() contact: any;
  @Input() website: string;


  constructor() { }

  ngOnInit() {
  }

}
